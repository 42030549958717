
export default (text, gender = 'M') => {

	// if FR, => OK
	if (/^fr\b/.test(navigator.language) === true) {

		if (text === 'Engagés' && gender === 'W') {
			return 'Engagées';
		}

		switch (text) {
			case 'STARTLIST':
				return 'STARTLIST';
			case 'SCHEDULED':
				return 'A VENIR';
			case 'RUNNING':
				return 'EN COURS';
			case 'OFFICIAL':
				return 'OFFICIEL';
			case 'FINISHED':
				return 'TERMINÉ';
		}
		return text;
	}

	switch (text) {
		case 'Place':
			return 'Rank';
		case 'Nom':
			return 'Name';
		case 'Année':
			return 'Year';
		case 'Ordre':
			return 'Lane';
		case 'Couloir':
			return 'Lane';
		case 'Résultat':
			return 'Result';
		case 'Temps':
			return 'Time';
		case 'Résultats':
			return 'Results';
		case 'Classement':
			return 'Rank';
		case 'Vent':
			return 'wind';
		case 'Compétition':
			return 'Competition';
		case 'Pas encore de classement':
			return 'No ranking yet';
		case 'Pas encore de start list':
			return 'No start list yet';
		case "Pas d'évènements":
			return 'No event yet';
		case 'Pas de compétitions':
			return 'No competition';
		case 'Athlète':
			return 'Competitor';
		case 'Pas d\'athlète':
			return 'No competitor found';
		case 'Résultats Athlétisme LIVE':
			return 'Athletics LIVE results';
		case 'Récentes passés':
			return 'Recent passed';
		case 'Prochaines':
			return 'Recent next';
		case 'Toutes':
			return 'All';
		case 'PDFs':
			return 'PDFs';
		case 'Engagés':
			return 'Engaged';
		case 'MDB':
			return 'FH';
	}

	return text;
}
